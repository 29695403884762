import VueI18n from "vue-i18n";
import Vue from 'vue';

Vue.use(VueI18n);

const messages = {
    "en": {
        "app_bar_title": "Course Registration",
        "terms_agreement_checkbox": {
            "text_part": "I agree to ",
            "link_text_part": "terms of participation",
            "text_health": "I approve",
            "link_text_health": "good health statement",
            "text_filming": "I authorize to",
            "link_text_filming": "use photographed materials",
        },
        "stepper": {
            "level1": "Identification",
            "level2": "Activity",
            "level3": "Personal Details",
            "level4": "Profile Picture",
            "level5": "Declerations",
        },
        "buttons": {
            "next": "Next",
            "prev": "Prev",
            "send": "Send"
        },
        "labels": {
            "identity_number": "Israeli ID Number",
            "passport_number": "Passport Number",
            "first_name": "First name",
            "last_name": "Last name",
            "first_name_en": "First name",
            "last_name_en": "Last name",
            "phone": "Phone",
            "email": "Email",
            "year_of_birth": "Year of birth",
            "profile_picture": "Profile Picture",
            "gender":"Gender",
        },
        "placeholders": {
            "identity_number": "9 digits israeli identity number",
            "passport_number": "any country passport number",
            "first_name": "",
            "last_name": "",
            "first_name_en": "",
            "last_name_en": "",
            "phone": "",
            "email": "",
            "gender":""
        },
        "subheaders": {
            "activity_selection": "Choose your activity",
            "order_selection": "Choose your order",
            "chev_nationality":"Nationality",
            "chev_dept":"Chevron department",
            "chev_site":"Chevron site"

        },
        "dialog": {
            "close": "Close",
            "yes_button": "Agree",
            "no_button": "Disagree",
            "terms_title_health": "Good health statement",
            "terms_body_health": "I have been informed that practical training includes a high level of physical exertion. I declare that I am in good health and do not suffer from a disease or disability that would prevent my participation in active training. In the event of any change to my health status, I am aware I must immediately notify company staff and/or instructors. My body temperature is within normal limits, and I have no symptoms that may indicate COVID-19, including cough, headache, etc. If such symptoms do appear, I am aware I can not participate in any training activity until providing a negative COVID-19 test result",
            "terms_title_part": "Terms of participation",
            "terms_body_part": "I declare that I am an adult (over 18 years) and am obligated to follow the safety instructions and guidance provided by the instructors at every work stage, including wearing personal protective gear during all training activities.",
            "terms_title_fliming": "Authorization to use photographed materials",
            "terms_body_filming": " am aware that video or photographs may be taken during training by Rescue One staff and/or course participants and approve the use of such materials for marketing purposes (internet sites, etc.)"

        },
        "alerts": {
            "register_success_title": "You Successfully Registered!",
            "register_success_body": "",
            "register_failed_title": "Something Went Wrong",
            "register_failed_body": "Please update your instructor"
        },
        "validation_errors":{
            "israliId_length":"ID number should contain 9 digits",
            "israliId_validation":"ID number is not valid"
        },
        "lists":{
            "gender":{
                "male":"Male",
                "female":"Female"
            }
        }


    }
    ,
    "heb": {
        "app_bar_title": "רישום לקורס",
        "terms_agreement_checkbox": {
            "text_part": "אישור תנאי",
            "link_text_part": "השתתפות בפעילות",
            "text_health": "",
            "link_text_health": "הצהרת בריאות",
            "text_filming": "אישור שימוש",
            "link_text_filming": "בחומרים מצולמים",
        },
        "stepper": {
            'level1': "הזדהות",
            "level2": "בחירת פעילות",
            "level3": "פרטים אישיים",
            "level4": "תמונה להסמכה",
            "level5": "הצהרות",
        },
        "buttons": {
            "next": "הבא",
            "prev": "הקודם",
            "send": "שליחה"
        },
        "labels": {
            "identity_number": "מספר זהות",
            "passport_number": "מספר דרכון",
            "first_name": "שם פרטי",
            "last_name": "שם משפחה",
            "first_name_en": "שם פרטי באנגלית",
            "last_name_en": "שם משפחה באנגלית",
            "phone": "טלפון",
            "email": "אימייל",
            "year_of_birth": "שנת לידה",
            "profile_picture": "תמונת פרופיל",
            "gender":"מין"
        },
        "placeholders": {
            "identity_number": "מספר תעודת זהות בן 9 ספרות",
            "passport_number": "דרכון ישראלי או זר",
            "first_name": "לדוגמא: ישראל",
            "last_name": "לדוגמא: כהן",
            "first_name_en": "לדוגמא: Israel",
            "last_name_en": "לדוגמא: Cohen",
            "phone": "לדוגמא: 0532742744",
            "email": "לדוגמא: israel@gmail.com",
        },
        "subheaders": {
            "activity_selection": "לאיזה קורס הגעת?",
            "order_selection": "על שם מי ההזמנה?"
        },
        "dialog": {
            "close": "סגירה",
            "yes_button": "אני מאשר/ת",
            "no_button": "אני לא מאשר/ת",
            "terms_title_health": "הצהרת בריאות",
            "terms_body_health": "אני מודע/ת לכך שההכשרה כוללת תרגול מעשי הדורש מאמץ פיזי גבוה. אני בריא/ה ואין לי כל מחלה או מגבלה המונעת ממני להשתתף בתרגולים המעשיים. במידה ואני סובל/ת מבעיה כלשהי אני יודע/ת כי חובתי לדווח עליה למדריך הפעילות באופן מיידי.  אין לי חום ואינני סובל/ת מתסמינים העלולוים להעיד על מחלת הקורונה כגון שיעול, כאבי ראש וכדומה. במידה ואת/ה סובל מאחד התסמינים אין לבצע הפעילות עד להצגת בדיקת קורונה שלילית",
            "terms_title_part": "תנאי השתתפות",
            "terms_body_part": "אני מצהיר/ה שאני מעל גיל 18 ומתחייב/ת לפעול על פי הוראות הבטיחות והנחיות המדריכים בכל שלבי העבודה ולהשתמש בציוד מגן אישי בכל שלבי האימון המעשי.",
            "terms_title_fliming": "אישור שימוש בחומרים מצולמים",
            "terms_body_filming": "אני מודע/ת לכך שבמהלך הקורס יתכן ואצולם על ידי משתתפי הקורס / חברת Rescue One ומאשר/ת להשתמש בתמונות אלו לצרכים פרסומיים (אתר אינטרנט וכיוצ״ב)"
        },
        "phoneInput": {
            "countrySelectorLabel": 'קידומת',
            "countrySelectorError": 'שגיאה בהזנת מספר טלפון',
            "phoneNumberLabel": 'מספר טלפון',
            "example": 'לדוגמא: '
        },
        "alerts": {
            "register_success_title": "ההרשמה לקורס הסתיימה בהצלחה!",
            "register_success_body": "",
            "register_failed_title": "אירעה שגיאה בתהליך ההרשמה",
            "register_failed_body": "נא לעדכן את צוות ההדרכה"
        },
        "validation_errors":{
            "israliId_length":"מספר ת.ז חייב להכיל 9 ספרות",
            "israliId_validation":"ת.ז אינה תקינה"
        },
        "lists":{
            "gender":{
                "male":"זכר",
                "female":"נקבה"
            }
        }

    }
}

export default new VueI18n({
    locale: 'heb',
    messages
})