import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,

  theme: {
    themes: {
      light: {
        primary: '#E32726',
        secondary: '#5F5F5F',
      }
    }
  }
});
