<template>
  <v-app>
    <v-container fluid>
      <v-select
        v-if="step == 1"
        v-model="$i18n.locale"
        v-on:change="changeRTL"
        :items="locales"
        label="Language"
      ></v-select>
      <v-app-bar color="primary" dense dark>
        <v-spacer></v-spacer>
        <v-app-bar-title absolute="true">{{
          $t("app_bar_title")
        }}</v-app-bar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-main>
        <FormA />
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
import FormA from "./components/FormA";

export default {
  name: "App",
  components: {
    FormA,
  },
  data: () => ({
    locales: [{ value: "en", text:"English" }, { value: "heb", text: "עברית" }],
  }),
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
    changeRTL() {
      if (this.$i18n.locale == "heb") {
        this.$vuetify.rtl = true;
      } else if (this.$i18n.locale == "en") {
        this.$vuetify.rtl = false;
      }
    },
  },
  computed: {
    step: {
      get() {
        return this.$store.state.step;
      },
    },
  },
  mounted: () => {
  },
  created: () => {

  },
};
</script>
