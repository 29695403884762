<template>
  <v-row justify="center">
    <v-dialog v-model="dialog.show" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          <h5>{{ dialog.title }}</h5>
        </v-card-title>
        <v-card-text>{{ dialog.body }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog.show = false">
            {{ $t("dialog.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialog;
      },
      set(value) {
        this.$store.dispatch("setDialog", value);
      },
    },
  },
};
</script>

<style>

</style>