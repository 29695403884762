import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment';
import vueRouter from 'vue-router';
import formA from './components/FormA.vue';
import i18n from './i18n';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const swalOptions = {
  confirmButtonColor: '#E32726',
  cancelButtonColor: '#E32726',
};
//global variables
Vue.prototype.$appName = 'רישום לקורס'
Vue.config.productionTip = false
Vue.use(Vuex);
Vue.use(vueRouter);
Vue.use(VueSweetalert2,swalOptions);
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

//routes
const router = new vueRouter({
  routes: [
    {path: '/:date',components: formA}
  ],
  mode: 'history'
})

new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
