const MAX_WIDTH = 320;
const MAX_HEIGHT = 320;

// const INPUT = document.getElementById('imgId');

function calculateSize(img, maxWidth, maxHeight) {
  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
}

export default function imgCompress(event) {
  const blobURL = URL.createObjectURL(event);
  const img = new Image();
  img.src = blobURL;
  return new Promise((resolve) => {
    img.onload = async function () {
      // Create a canvas element setting the width and height to match the new dimensions of the image.
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Create a 2D context object and draw the image on the canvas
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Export the image with the desired output quality
      let file;

      canvas.toBlob(
        (blob) => {
          file = new File([blob], 'image.jpg', {
            type: 'image/jpeg',
            lastModified: new Date(),
          });
          // Now you have a File object, which you can handle as needed
          console.log(file);
          resolve(file);
        },
        'image/jpeg',
        0.95
      );
    };
  });
}
